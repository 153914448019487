<template>
    <div style="background-color: #f4f7f9;">
        <div class="body">
            <div class="head">
                <div class="icon"><img :src="this.data.items[0].icon"></div>
                <div class="name">模型库列表</div>
                <div class="contact"><a @click="handleClick">前往MoHub下载 &gt; &gt;</a> </div>
                <div class="clear"></div>
            </div>
            <ul v-for="(item, index) in data.items" :key="index">
                <li v-for="(library, index) in item.libraryList" :key="index">
                    <div class="image">
                        <img :src="library.cover" />
                    </div>
                    <div class="line">
                        <img src="../../assets/images/official/line.jpg" />
                    </div>
                    <div class="text">
                        <div class="title">{{ library.title }}</div>
                        <!-- <div class="description">{{ library.description }}</div> -->
                        <div class="description" v-html="library.description"></div>
                    </div>
                    <!-- <a @click="toUrl(library.router)">
                        <div class="image">
                            <img :src="library.cover" />
                        </div>
                        <div class="text">
                            <div class="title">{{ library.title }}</div>
                            <div class="description">{{ library.description }}</div>
                            <div class="more">查看详情>></div>
                        </div>
                    </a> -->
                </li>
                <div class="clear"></div>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  created() {
    console.log('11111', this.data);
  },
  methods: {
    handleClick() {
      window.open('https://mohub.net', '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.clear {
    clear: both;
}

.body {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 90px;
}

.head {
    padding-bottom: 14px;
    border-bottom: 2px solid #3e3a39;

    .icon {
        width: 55px;
        // height: 60px;
        float: left;
    }

    .icon img {
        max-height: 100%;
        width: 100%;
    }

    .name {
        float: left;
        font-size: 24px;
        padding-top: 12px;
        margin-left: 30px;
    }
    .contact {
        float: right;
        font-size: 24px;
        padding-top: 12px;
        margin-left: 30px;
        color: #3c7bbf;
    }

    .contact a {
        cursor: pointer;
        color: #3c7bbf;
    }
}

.body ul {
    margin-left: -3%;
}

.body li {
    height: 350px;
    padding-left: 1.8%;
    border-bottom: 1px solid #acacac;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 27.5%;
    height: 100%;
    float: left;
}

.image img {
    width: 100%;
    max-height: 90%;
    display: block;
    margin: auto;
}

.line {
    height: 240px;
    width: 4.5%;
    float: left;
    text-align: center;
}

.line img {
    height: 100%;
}

.text {
    width: 68%;
    float: right;

    .title {
        color: #585858;
        font-size: 30px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }

    .description {
        color: #676767;
        font-size: 18px;
        line-height: 36px;
        margin: 40px 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        /deep/ a{
            color: #3c7bbf;
        }
    }

}

@media screen and (max-width: 700px) {
    .body ul {
        width: 96%;
        margin: 0 auto;
        // padding-top: 5vw;
    }

    .body li {
        // width: 100%;
        // margin: 20px 0;
        height: auto;
        padding: 5vw 0;
        display: block;
    }

    .image {
        width: 80%;
        margin: 0 auto;
        float: none;
    }

    .image img {
        max-height: 60vw;
    }

    .text {
        float: none;
        width: 100%;

        .title {
            font-size: 5vw;
            margin-top: 2vw;
        }

        .description {
            display: block;
            margin: 2vw 0;
            font-size: 3.8vw;
            line-height: 5vw;
        }
    }

    .line {
        display: none;
    }
}
</style>
