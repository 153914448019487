<template>
    <div>
        <list :data="library"></list>
    </div>
</template>

<script>
import list from "@/components/block/library.vue";
export default {
    components: {
        list
    },
    data() {
        return {
            library: {
                items: []
            }
        };
    },
    created() {
        this.getLibraryList();
        console.log(this.toolbox)
    },
    methods: {
        async getLibraryList() {
            const res = await this.$api.getLibraryList();
            if (res.code == 200) {
                this.library.items = res.data;
                console.log(res.data, 'library')
            }
        },
    },
};
</script>

<style></style>
